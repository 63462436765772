/* .root {
    margin: 0;
    padding: 0
  }
  
  @media all {
    .page-break {
      display: none;
    }
  }
   */
  /* @media print {
    html,
    body {
     height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact; 
    }
  } */
  
  @media print {
    .page-break {
      page-break-after: always;
    }
  }
  
  @page {
    size: auto;
    margin: 20mm;
  }